<template>
  <div>
    <!-- {{pagamento}} -->
    <b-form @submit.prevent="salvar">
      <div class="row">
        <div class="col-12">
          <div class="alert alert-warning text-center">
            <b>Valor à Pagar</b> <br /><span>{{
              valorAberto | currencyWithMask
            }}</span>
          </div>
          <div class="col-12 text-center">
            <span
              class="text-danger text-center"
              v-if="descontoPorForma.status === true"
            >
              * {{ descontoPorForma.msg }}
            </span>
          </div>
        </div>
      </div>
      <div
        class="formas"
        :class="{ disabled: notChange }"
      >
        <div
          class="forma-item"
          :class="{ selected: pagamento.forma == 'Dinheiro' }"
          @click="selecionarForma('Dinheiro')"
        >
          Dinheiro
          <div class="icon"><b-icon-cash /></div>
        </div>
        <div
          class="forma-item"
          :class="{ selected: pagamento.forma == 'Cartão' }"
          @click="selecionarForma('Cartão')"
        >
          Cartão
          <div class="icon"><b-icon-credit-card /></div>
        </div>
        <div
          class="forma-item"
          :class="{ selected: pagamento.forma == 'PIX' }"
          @click="selecionarForma('PIX')"
        >
          PIX / TEF
          <div class="icon"><i class="fa fa-exchange-alt" /></div>
        </div>
        <div
          class="forma-item"
          :class="{ selected: pagamento.forma == 'Convenio' }"
          @click="selecionarForma('Convenio')"
        >
          Convenio
          <div class="icon"><i class="fa fa-industry" /></div>
        </div>
        <div
          class="forma-item"
          :class="{ selected: pagamento.forma == 'DEF' }"
          @click="selecionarForma('DEF')"
        >
          Desconto Folha
          <div class="icon"><i class="fa fa-user-tag" /></div>
        </div>
        <div
          class="forma-item"
          :class="{ selected: pagamento.forma == 'Crédito' }"
          @click="selecionarForma('Crédito')"
        >
          Crédito Cliente
          <div class="icon"><i class="fa fa-wallet" /></div>
        </div>
      </div>

      <div class="mt-2 p-sm-3">
        <div v-show="pagamento.forma == 'Crédito'">
          <div
            v-if="!creditos || creditos.length <= 0"
            class="text-center text-danger py-3 px-4"
          >
            Não existe créditos disponíveis para este cliente.
          </div>
          <div v-if="creditos && creditos.length > 0">
            <div class="listCreditos">
              <b-card
                class="cardCredito"
                :class="{
                  CardSelected:
                    pagamento.credito && pagamento.credito.id == credito.id,
                }"
                v-for="credito in creditos"
                :key="`cred_${credito.id}`"
                @click="selecionarCredito(credito)"
              >
                <div class="d-flex justify-content-between w-100">
                  <div>
                    <b>Código</b><br />
                    {{ credito.id.toString().padStart(5, "0") }}
                  </div>
                  <div class="text-center">
                    <b>Valor Disponível</b>
                    <br />
                    {{
                      (credito.valor - credito.valor_usado) | currencyWithMask
                    }}
                  </div>
                </div>
              </b-card>
            </div>
          </div>
        </div>
        <div v-show="pagamento.forma == 'Dinheiro'">
          <b-form-group label="Valor">
            <b-input
              v-model="pagamento.valor"
              type="text"
              v-money="moneyMask"
              ref="txtDinheiro"
            />
          </b-form-group>
        </div>
        <div v-if="pagamento.forma == 'Cartão'">
          <div class="row justify-content-between">
            <div class="col-6 col-sm-3">
              <b-form-group label="Valor">
                <b-input
                  v-model="pagamento.valor"
                  type="text"
                  v-money="moneyMask"
                />
              </b-form-group>
            </div>
            <div class="col-6 col-sm-4 text-center">
              <b-form-group label="Tipo">
                <b-form-radio-group
                  buttons
                  button-variant="info"
                  v-model="pagamento.tipo"
                  :options="['Débito', 'Crédito']"
                >
                </b-form-radio-group>
              </b-form-group>
            </div>
            <div class="col-6 col-sm-3">
              <b-form-group
                label="Parcelas"
                :disabled="pagamento.tipo != 'Crédito'"
              >
                <b-spinbutton
                  v-model="pagamento.parcelas"
                  max="12"
                  min="1"
                  :disabled="pagamento.tipo != 'Crédito'"
                />
              </b-form-group>
            </div>
          </div>
          <!-- {{bandeiraSelected}}  -->
          <div
            class="text-danger"
            v-if="
              bandeiraSelected &&
              bandeiraSelected.debito == 0 &&
              pagamento.tipo == 'Débito'
            "
          >
            <b>Atenção!</b> A bandeira {{ bandeiraSelected.nome }} não aceita
            débito.
          </div>

          <div
            class="text-danger"
            v-if="
              bandeiraSelected &&
              bandeiraSelected.credito == 0 &&
              pagamento.tipo == 'Crédito'
            "
          >
            <b>Atenção!</b> A bandeira {{ bandeiraSelected.nome }} não aceita
            crédito.
          </div>
          <div class="row justify-content-between">
            <div class="col-12">
              <SelectBandeira
                @bandeiraComplete="bandeiraSelected = $event"
                :customReduce="
                  (band) => {
                    return { id: band.id, nome: band.nome };
                  }
                "
                v-model="pagamento.bandeira"
              />
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-6 col-sm-5">
              <b-form-group label="Autorização">
                <b-input
                  v-model="pagamento.autorizacao"
                  @blur="verificarAutDoc"
                />
              </b-form-group>
            </div>
            <div class="col-6 col-sm-5">
              <b-form-group label="CV">
                <b-input
                  v-model="pagamento.documento"
                  @blur="verificarAutDoc"
                />
              </b-form-group>
            </div>
          </div>
        </div>
        <div v-if="pagamento.forma == 'PIX'">
          <div class="row">
            <div
              class="col-6 col-sm-3"
              v-if="!qrCodeGerado"
            >
              <b-form-group label="Valor">
                <b-input
                  type="text"
                  v-money="moneyMask"
                  v-model="pagamento.valor"
                  ref="txtDinheiro"
                />
              </b-form-group>
            </div>
            <div class="col-6 pt-4">
              <button
                class="btn btn-success btn-lg m-auto"
                :disabled="gerandoQR"
                type="button"
                @click="gerarQrCode"
                v-if="!qrCodeGerado"
              >
                <span v-if="!gerandoQR"
                  ><i class="fa fa-qrcode"></i> Gerar QRCode</span
                >
                <span v-if="gerandoQR"
                  ><b-spinner
                    size="small"
                    small
                  />
                  aguarde...</span
                >
              </button>
            </div>
            <div
              class="col-12"
              v-if="!qrCodeGerado || (qrCodeGerado && !qrCodeGerado.pixCode)"
            >
              <hr />
              <div class="row">
                <div class="col-6">
                  <b-form-group label="Localizar por Identificador">
                    <b-input
                      type="text"
                      v-model="qrCodeIdentificador"
                      ref="txtIDQRCOD"
                      v-on:keyup.enter="atualizarPIX"
                      autocomplete="off"
                      name="txtInptLozPx"
                      id="txtInptLozPx"
                    />
                  </b-form-group>
                </div>
                <div class="col-6 pt-4">
                  <b-btn
                    variant="primary"
                    @click="atualizarPIX"
                    :disabled="atualizandoQR"
                  >
                    <span v-if="atualizandoQR">
                      <b-spinner
                        small
                        size="sm"
                      />
                      localizando e validando</span
                    >
                    <span v-else> <b-icon-search /> Localizar</span>
                  </b-btn>
                  <b-btn
                    variant="warning"
                    type="button"
                    @click="openListPix"
                  >
                    <b-icon-list-check /> Lista de PIX
                  </b-btn>
                </div>
              </div>
            </div>
            <div
              class="col-12 p-3"
              v-if="pixErrorMessage && pixErrorMessage != ''"
            >
              <b-alert show>{{ pixErrorMessage }}</b-alert>
            </div>
            <div
              class="col-12"
              v-if="qrCodeGerado && !gerandoQR && qrCodeGerado.pixQrCode"
            >
              <div class="row">
                <div class="col-12 col-sm-6 text-center">
                  <img
                    v-if="qrCodeGerado && qrCodeGerado.pixQrCode"
                    :src="qrCodeGerado.pixQrCode"
                  />
                  <br />
                  <span v-if="qrCodeGerado && qrCodeGerado.status != 'PAGO'">
                    <small v-if="pixTempoExpirar.indexOf('-') < 0">
                      Tempo restante para expirar {{ pixTempoExpirar }}
                    </small>
                    <small
                      v-else
                      class="text-danger"
                    >
                      QRCode expirado!
                    </small>
                  </span>
                </div>
                <div
                  class="col-12 col-sm-6"
                  style="margin-right: 1px solid #ccc"
                >
                  <h2>Identificador: {{ qrCodeGerado.id }}</h2>
                  <hr />
                  <b>Devedor</b><br />
                  <small
                    >{{ qrCodeGerado.devedor_nome }} - CPF:
                    {{ qrCodeGerado.devedor_cpf }}</small
                  >
                  <br />
                  <b>Valor</b><br />
                  <small>{{ qrCodeGerado.valor | currencyWithMask }}</small>
                  <hr />
                  <b>Código PIX (copia e cola)</b>
                  <br />
                  <small>
                    {{ qrCodeGerado.pixCode }}
                  </small>
                  <hr />
                  <b>STATUS: </b> {{ qrCodeGerado.status }}
                  {{
                    qrCodeGerado.status == "ATIVA"
                      ? " - Aguardando pagamento"
                      : ""
                  }}
                  <span
                    class="text-success"
                    v-if="qrCodeGerado.status == 'PAGO'"
                  >
                    <h3 class="text-center">
                      <b>
                        <b-icon-check-circle
                          style="font-size: 90px; width: 50px; height: 50px"
                        />
                        <br />
                        PAGAMENTO REALIZADO COM SUCESSO!</b
                      >
                    </h3>
                  </span>

                  <div
                    v-if="
                      qrCodeGerado.status == 'DEVOLVIDO' &&
                      qrCodeGerado.devolucoes &&
                      qrCodeGerado.devolucoes.length > 0
                    "
                  >
                    <hr />
                    <b>Identificador: </b> {{ qrCodeGerado.devolucoes[0].id
                    }}<br />
                    <b>Identificador Retorno: </b>
                    {{ qrCodeGerado.devolucoes[0].rtrId }}<br />
                    <b>Data Solicitação: </b>
                    {{
                      qrCodeGerado.devolucoes[0].horario.solicitacao
                        | moment("DD/MM/YYYY HH:mm:ss")
                    }}<br />
                    <b>Data Devolução: </b>
                    {{
                      qrCodeGerado.devolucoes[0].horario.liquidacao
                        | moment("DD/MM/YYYY HH:mm:ss")
                    }}<br />
                    <b>Valor </b>
                    {{ qrCodeGerado.devolucoes[0].valor | currencyWithMask
                    }}<br />
                    <b>Status </b> {{ qrCodeGerado.devolucoes[0].status }}<br />

                    <hr />
                  </div>

                  <br />
                  <br />
                  <b-btn
                    variant="success"
                    block
                    v-if="
                      !['PAGO', 'EXPIRADO', 'DEVOLVIDO'].includes(
                        qrCodeGerado.status
                      )
                    "
                    :disabled="atualizandoQR"
                    @click="atualizarPIX"
                  >
                    <span v-if="atualizandoQR">
                      <b-spinner
                        small
                        size="sm"
                      />
                      atualizando status...</span
                    >
                    <span v-else> <b-icon-recycle /> Atualizar Status </span>
                  </b-btn>
                  <b-btn
                    block
                    v-if="!['PAGO', 'EXPIRADO'].includes(qrCodeGerado.status)"
                    variant="warning"
                    type="button"
                    @click.stop="openSendWpp"
                    ><b-icon-whatsapp />
                    <span v-if="qrCodeGerado.status == 'DEVOLVIDO'">
                      Enviar comprovante de devolução por whatsapp.
                    </span>
                    <span v-else> Enviar por whatsapp </span>
                  </b-btn>
                  <b-btn
                    variant="secondary"
                    block
                    v-if="qrCodeGerado"
                    @click="qrCodeGerado = null"
                    ><b-icon-back /> Voltar
                  </b-btn>
                  <b-btn
                    variant="danger"
                    v-if="podeCancelarPix"
                    block
                    @click="cancelarPIX"
                  >
                    <span v-if="!cancelandoPix">
                      <b-icon-x-circle /> Cancelar/Devolver PIX
                    </span>
                    <span v-else>
                      <b-spinner
                        small
                        size="sm"
                      />
                      cancelando...
                    </span>
                  </b-btn>
                </div>
              </div>
            </div>
          </div>

          <!-- <b-form-group  label="Comprovante">
            <b-file
              placeholder="Selecione um arquivo"
              drop-placeholder="Solte um arquivo aqui"
            />
          </b-form-group> -->
        </div>
        <div v-if="pagamento.forma == 'Convenio' || pagamento.forma == 'DEF'">
          <div class="row">
            <div class="col-12">
              <b-form-group
                :label="
                  pagamento.forma == 'Convenio' ? 'Empresa' : 'Funcionário'
                "
              >
                <select-paciente
                  :tipo="[
                    pagamento.forma == 'Convenio'
                      ? 'convenio'
                      : pagamento.forma == 'DEF'
                      ? 'funcionario'
                      : 'paciente',
                  ]"
                  :selecionado="pagamento.entidadeId"
                  :showBotaoAdd="false"
                  :showBotaoEdit="false"
                  @paciente="setEntidade"
                />
              </b-form-group>
            </div>
            <div class="col-6 col-sm-4">
              <b-form-group label="Valor">
                <b-input
                  type="text"
                  v-money="moneyMask"
                  v-model="pagamento.valor"
                  ref="txtDinheiro"
                />
              </b-form-group>
            </div>
            <div class="col-6 col-sm-4">
              <b-form-group label="Parcelas">
                <b-spinbutton
                  v-model="pagamento.parcelas"
                  :max="12"
                  :min="1"
                />
              </b-form-group>
            </div>
            <div class="col-6 col-sm-4">
              <b-form-group label="Vale/Autorização">
                <b-input v-model="pagamento.vale" />
              </b-form-group>
            </div>
          </div>
        </div>
      </div>

      <botoes-salvar-cancelar
        v-if="
          pagamento.forma !== 'PIX' ||
          (pagamento.forma == 'PIX' &&
            qrCodeGerado &&
            qrCodeGerado.status == 'PAGO')
        "
        @cancelar="
          $emit('creating', null);
          $emit('close');
        "
        @salvar="salvar"
      ></botoes-salvar-cancelar>
    </b-form>
    <b-modal
      id="modal-wpp"
      hide-footer
      title="Enviar cobrança para whatsapp"
    >
      <b-form-group label="Número">
        <vue-simple-suggest
          :suggestion-click="true"
          v-model="wppNumber"
          :list="telefonesDisponiveis"
          :filter-by-query="true"
          ref="select-num-wpp"
          mode="select"
          :controls="{
            selectionUp: [38, 33],
            selectionDown: [40, 34],
            select: [13, 36],
            showList: [40],
            hideList: [27, 35, 13],
          }"
        >
          <input
            class="form-control optional-custom-input"
            v-model="wppNumber"
            v-mask="['(##) ####-####', '(##) #####-####']"
            autocomplete="off"
            :id="`txtField${Math.random()}`"
            :name="`txtField${Math.random()}`"
          />
        </vue-simple-suggest>
        <b-alert
          :show="wppAlert.show"
          class="mt-2"
          :variant="wppAlert.type"
        >
          {{ wppAlert.message }}
        </b-alert>
        <botoes-salvar-cancelar
          :okText="'Enviar'"
          :okIcon="'fab fa-whatsapp'"
          @salvar="enviarWpp"
          :saving="enviandoWpp"
          @cancelar="$bvModal.hide('modal-wpp')"
        />
      </b-form-group>
    </b-modal>

    <b-modal
      id="modal-list-pix"
      hide-footer
      size="lg"
      v-if="caixa && caixa.empresa_id"
    >
      <RelatorioPixForm
        :selectable="true"
        :hideFiltros="true"
        :clinica="caixa.empresa_id"
        @selected="selectPix"
      />
    </b-modal>
  </div>
</template>

<script>
import BotoesSalvarCancelar from "../common/BotoesSalvarCancelar.vue";
import Validadores from "../../helpers/Validadores";
import SelectPaciente from "../common/SelectPaciente.vue";
import SelectBandeira from "../common/SelectBandeira.vue";
import CaixasLib from "../../libs/CaixasLib";
import VueSimpleSuggest from "vue-simple-suggest";
import "vue-simple-suggest/dist/styles.css";
import WppServ from "../../utils/WhatsappService";
import moment from "moment";
import RelatorioPixForm from "./RelatoriosForm/RelatorioPixForm.vue";
import EntidadesLib from "../../libs/EntidadesLib";

export default {
  components: {
    BotoesSalvarCancelar,
    SelectPaciente,
    SelectBandeira,
    VueSimpleSuggest,
    RelatorioPixForm,
  },
  props: {
    notChange: {
      type: Boolean,
      default: false,
    },
    valorAberto: {
      type: Number,
      default: 0,
    },
    descontoPorForma: {
      type: Object,
      default: () => ({
        status: false,
        msg: "Há um desconto para dessa forma de pagamento.",
        valor: "",
      }),
    },
    edit: {
      type: Object,
      default: null,
    },
    convenio: Object,
    funcionario: Object,
    caixa: Object,
  },
  mounted() {
    this.pagamento.valor = this.formatFloatToReal(this.valorAberto);
    console.log("empresa", this.caixa);

    this.carregarEdit();

    if (!this.edit && this.convenio) {
      this.pagamento.forma = "Convenio";
      this.pagamento.entidadeId = this.convenio.convenio_id;
    }

    this.loadCreditos();
  },
  data() {
    return {
      verificandoAutDoc: false,
      creditos: [],
      pagamento: {
        forma: null,
        tipo: "",
        parcelas: 1,
        vale: "",
        autorizacao: "",
        documento: "",
        entidadeId: 0,
        entidade: null,
        bandeira: null,
        pix: {},
        credito: null,
      },
      gerandoQR: false,
      atualizandoQR: false,
      qrCodeGerado: null,
      bandeiraSelected: {},
      timerAtualizaPix: null,
      pixErrorMessage: "",
      qrCodeIdentificador: null,
      podeCancelarPix: true,
      wppNumber: "",
      telefonesDisponiveis: [],
      enviandoWpp: false,
      wppAlert: {
        show: false,
        type: "success",
        message: "",
      },
      pixTempoExpirar: "",
      timerTempoPix: null,
      cancelandoPix: false,
    };
  },
  watch: {
    "pagamento.tipo": function () {
      if (this.pagamento.tipo == "Débito") {
        this.pagamento.parcelas = 1;
      }
    },
    "pagamento.forma": function () {
      if (
        this.pagamento.forma == "DEF" &&
        this.funcionario &&
        this.funcionario.funcionario &&
        this.funcionario.funcionario === 1
      ) {
        this.pagamento.entidadeId = this.funcionario.id;
        this.setEntidade(this.funcionario);
      } else if (
        this.pagamento.forma == "Convenio" &&
        this.funcionario &&
        this.funcionario.convenio_id
      ) {
        this.pagamento.entidadeId = this.funcionario.convenio_id;
        this.setEntidade({ id: this.funcionario.convenio_id });
      }
    },
    "pagamento.valor": function () {
      if (this.pagamento.forma == "PIX") {
        //this.qrCodeGerado = null;
      }
    },
    pagamento: {
      deep: true,
      handler(newV) {
        console.log("asda", this.pagamento, this.funcionario);

        this.$emit("creating", newV);
      },
    },
    qrCodeGerado: function () {
      this.pagamento.pix = this.qrCodeGerado;
      if (this.timerAtualizaPix) {
        clearInterval(this.timerAtualizaPix);
      }
      this.timerAtualizaPix = null;
      //this.pixTempoExpirar = "";

      if (
        this.qrCodeGerado &&
        this.qrCodeGerado.status &&
        this.qrCodeGerado.status === "ATIVA"
      ) {
        this.timerAtualizaPix = setTimeout(() => {
          this.atualizarPIX();
        }, 2000);
      }
    },
    valorAberto() {
      this.pagamento.valor = this.formatFloatToReal(this.valorAberto);
    },
    edit: function () {
      this.carregarEdit();
    },
    funcionario: function () {
      this.loadCreditos();
    },
  },
  computed: {},
  methods: {
    async verificarAutDoc(showAsToast = true) {
      let result = false;
      this.verificandoAutDoc = true;
      try {
        if (this.pagamento.autorizacao && this.pagamento.documento) {
          let res = await CaixasLib.verificarAutDoc(
            this.pagamento.autorizacao,
            this.pagamento.documento
          );
          if (res && res.success) {
            result = true;
            if (showAsToast) {
              this.$swal.fire({
                title: "Autorização e Documento disponível",
                icon: "success",
                toast: showAsToast,
                timer: showAsToast ? 1000 : undefined,
                position: showAsToast ? "top-right" : undefined,
                showConfirmButton: showAsToast ? false : true,
              });
            }
          } else {
            this.$swal.fire({
              title: "Autorização e Documento já utilizado anteriormente",
              icon: "error",

              toast: showAsToast,
              timer: showAsToast ? 1000 : undefined,
              position: showAsToast ? "top-right" : undefined,
              showConfirmButton: showAsToast ? false : true,
            });
          }
        }
      } catch (error) {
        console.log("ERRO AO VERIFICAR DOC/AUTORIZAÇÃO", error);
      }
      this.verificandoAutDoc = false;
      return result;
    },
    async loadCreditos() {
      if (this.funcionario && this.funcionario.id) {
        let res = await EntidadesLib.getCreditos(this.funcionario.id);
        console.log("creditos", res);
        if (res && res.success) {
          this.creditos = res.data;
        }
      }
    },
    selectPix(pix) {
      this.$bvModal.hide("modal-list-pix");

      this.qrCodeIdentificador = pix.id;
      this.qrCodeGerado = { id: pix.id };
      this.atualizarPIX();
    },
    openListPix() {
      this.$bvModal.show("modal-list-pix");
    },
    async cancelarPIX() {
      if (
        this.qrCodeGerado &&
        this.qrCodeGerado.id &&
        this.qrCodeGerado.status === "PAGO"
      ) {
        this.$swal
          .fire({
            title: "Tem certeza?",
            text: "Você tem certeza que dejsea cancelar o PIX? O valor será devolvido para o cliente e não será possível reverter a situação!",
            showCancelButton: true,
            cancelButtonText: "Não. Cancelar!",
            confirmButtonText: "Sim. Devolver!",
            icon: "question",
          })
          .then(async (resp) => {
            if (resp.isConfirmed) {
              this.cancelandoPix = true;
              try {
                await CaixasLib.cancelarPix(
                  this.qrCodeGerado.id,
                  this.qrCodeGerado.valor
                );
                this.atualizarPIX();
              } catch (error) {
                console.log("ERRO AO SOLIC. CANCELAR PIX", error);
              }
              this.cancelandoPix = false;
            }
          });
      }
    },
    async enviarWpp() {
      this.wppAlert.show = true;
      if (this.wppNumber.trim() == "" || this.wppNumber.length < 14) {
        this.wppAlert.type = "danger";
        this.wppAlert.message = "Informe um número válido";
        return;
      }
      this.enviandoWpp = true;
      // try {

      if (this.qrCodeGerado.status == "ATIVA") {
        let res1 = await WppServ.enviarTextoSimples(
          this.wppNumber,
          "PIX gerado com sucesso!\nUtilize a câmera do celular para ler o QRCode ou 'Copie e Cole' o código de pagamento no seu aplicativo de banco."
        );
        let res2 = await WppServ.enviarImagem(
          this.wppNumber,
          this.qrCodeGerado.pixQrCode,
          ""
        );
        await WppServ.enviarTextoSimples(
          this.wppNumber,
          "CODIGO PARA PAGAMENTO ABAIXO, UTILIZE COPIAR E COLAR"
        );
        let res3 = await WppServ.enviarTextoSimples(
          this.wppNumber,
          this.qrCodeGerado.pixCode
        );
        console.log("ressss", res1, res2, res3);
      } else if (
        this.qrCodeGerado.status == "DEVOLVIDO" &&
        this.qrCodeGerado.devolucoes &&
        this.qrCodeGerado.devolucoes.length > 0
      ) {
        let text = `Solicitação de devolução de PIX\n*Identificador:*\n ${
          this.qrCodeGerado.devolucoes[0].id
        }\n*Identificador de Retorno:* \n ${
          this.qrCodeGerado.devolucoes[0].rtrId
        }\n\n*Data da Solicitação Devolução:* \n ${
          this.qrCodeGerado.devolucoes[0].horario.solicitacao
            ? moment(
                this.qrCodeGerado.devolucoes[0].horario.solicitacao
              ).format("DD/MM/YYYY HH:mm:ss")
            : "não solicitado"
        }\n\n*Data da Devolução:*\n ${
          this.qrCodeGerado.devolucoes[0].horario.liquidacao
            ? moment(this.qrCodeGerado.devolucoes[0].horario.liquidacao).format(
                "DD/MM/YYYY HH:mm:ss"
              )
            : "não confirmado"
        }\n\n*Valor:*\n ${this.formatFloatToReal(
          this.qrCodeGerado.devolucoes[0].valor
        )}\n\n*Status:* \n ${this.qrCodeGerado.devolucoes[0].status}
        `;
        await WppServ.enviarTextoSimples(this.wppNumber, text);
      }

      this.wppAlert.type = "success";
      this.wppAlert.message = "Informações enviadas com sucesso!";
      this.$bvModal.hide("modal-wpp");

      // } catch (error) {
      // console.log("ERROR ON SEND WPP", error);
      // }
      this.enviandoWpp = false;
    },
    openSendWpp() {
      this.$bvModal.show("modal-wpp");

      this.$nextTick().then(() => {
        if (this.funcionario && this.funcionario.telefones) {
          this.wppNumber = this.funcionario.telefones[0].telefone;
          console.log("aa", this.wppNumber);

          this.telefonesDisponiveis = this.funcionario.telefones.map(
            (it) => it.telefone
          );

          console.log("aa", this.wppNumber);
        }
      });
    },
    async atualizarPIX() {
      this.pixErrorMessage = "";
      if (
        !this.qrCodeGerado &&
        this.qrCodeIdentificador &&
        this.qrCodeIdentificador != ""
      ) {
        this.qrCodeGerado = {
          id: this.qrCodeIdentificador,
        };
      }
      if (this.qrCodeGerado && this.qrCodeGerado.id) {
        let id = this.qrCodeGerado.id;
        this.atualizandoQR = true;
        try {
          console.log("func", this.funcionario);
          this.qrCodeGerado = await CaixasLib.consultarPix(
            this.qrCodeGerado.id
          );
          if (typeof this.qrCodeGerado === "undefined") {
            //setTimeout(() => this.atualizarPIX(), 100);
            this.pixErrorMessage = "Conexão instável. Tente novamente.";
            this.qrCodeIdentificador = id;
          } else {
            console.log("result", this.qrCodeGerado);
            this.podeCancelarPix =
              this.qrCodeGerado.status && this.qrCodeGerado.status == "PAGO";
            this.pagamento.valor = this.formatFloatToReal(
              this.qrCodeGerado.valor
            );

            if (
              this.qrCodeGerado.success === false ||
              (this.qrCodeGerado && !this.qrCodeGerado.pixCode)
            ) {
              this.qrCodeGerado = null;
              this.pixErrorMessage = "Identificador não encontrado";
            }
            if (
              this.qrCodeGerado &&
              this.qrCodeGerado.venda &&
              this.qrCodeGerado.venda.venda_id
            ) {
              this.pixErrorMessage = `PIX JÁ UTILIZADO NA VENDA CODIGO ${this.qrCodeGerado.venda.venda_id}`;
              this.qrCodeGerado = null;
            }

            if (this.qrCodeGerado) {
              let tempoMinutos = moment(this.qrCodeGerado.processAt)
                .add(this.qrCodeGerado.expiracao, "s")
                .diff(moment(), "minutes");
              let tempoSegundos = parseInt(
                moment(this.qrCodeGerado.processAt)
                  .add(this.qrCodeGerado.expiracao, "s")
                  .diff(moment(), "seconds") % 60
              );
              this.pixTempoExpirar =
                (tempoMinutos < 10 ? "0" : "") +
                tempoMinutos +
                ":" +
                (tempoSegundos < 10 ? "0" : "") +
                tempoSegundos;
            }
          }
        } catch (error) {
          console.log("ERROR ON GERAR PIX", error);
        }
        this.atualizandoQR = false;
      }
    },
    async gerarQrCode() {
      this.gerandoQR = true;
      this.pixTempoExpirar = "";
      this.pixErrorMessage = "";
      try {
        console.log("func", this.funcionario);
        this.qrCodeGerado = await CaixasLib.createPix(
          this.funcionario.razao_social
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, ""),
          this.funcionario.cpf,
          this.formatValorReal(this.pagamento.valor),
          `SAUDE SOCIAL PAGAMENTO DE ${this.funcionario.razao_social}`,
          this.caixa
            ? this.caixa.empresa_id
            : this.user.empresa_id
            ? this.user.empresa_id
            : 0
        );
        this.podeCancelarPix = false;
      } catch (error) {
        console.log("ERROR ON GERAR PIX", error);
      }
      this.gerandoQR = false;
    },
    carregarEdit() {
      if (this.edit) {
        console.log(this.edit);
        this.pagamento = Object.assign(this.pagamento, this.edit);
        if (this.pagamento.pix) {
          this.qrCodeGerado = this.pagamento.pix;
        }
        if (this.pagamento.entidade) {
          this.setEntidade(this.pagamento.entidade);
        }
        this.$forceUpdate();
      }
    },
    selecionarForma(forma) {
      if (!this.notChange) {
        this.$emit("mudouForma", forma);
        this.pagamento.forma = forma;
      }
    },
    setEntidade(paciente) {
      console.log("SELECIONOU ENTIDADE AQUI", paciente);
      if (paciente) {
        if (
          this.pagamento.forma == "DEF" &&
          !Validadores.string.validarCPF(paciente.cpf)
        ) {
          this.$swal(
            "CPF INVÁLIDO!",
            "Você não pode selecioanr um funcionário sem o CPF cadastrado corretamente!",
            "error"
          );
          this.pagamento.entidade = null;
          this.pagamento.entidadeId = 0;
          return;
        }
        this.pagamento.entidade = paciente;
        this.pagamento.entidadeId = paciente.id;
      } else {
        this.pagamento.entidade = null;
        this.pagamento.entidadeId = 0;
      }
    },
    selecionarCredito(credito) {
      if (this.pagamento.credito && this.pagamento.credito.id == credito.id) {
        this.pagamento.credito = null;
      } else {
        this.pagamento.credito = credito;
      }
    },
    async salvar() {
      console.log(
        "valores",
        Math.ceil(this.formatValorReal(this.pagamento.valor)),
        Math.ceil(this.valorAberto)
      );
      const erros = [];
      if (!this.pagamento.forma) {
        erros.push("Selecione a forma de pagamento");
      }
      if (this.formatValorReal(this.pagamento.valor) <= 0) {
        erros.push("Informe um valor válido");
      }
      if (this.pagamento.forma == "Crédito" && !this.pagamento.credito) {
        erros.push("Selecione um crédito para ser utilizado!");
      }
      if (this.pagamento.forma == "Crédito" && this.pagamento.credito) {
        let valAberto = Math.ceil(this.valorAberto);
        let valDisponivel =
          this.pagamento.credito.valor - this.pagamento.credito.valor_usado;
        if (valAberto > valDisponivel) {
          this.pagamento.valor = valDisponivel;
          let canContinue = await this.$swal({
            title: "Divergência no valor do crédito!",
            text: "O valor á pagar é maior do que disponível no crédito selecionado. Deseja utilizar apenas o valor disponível e interar o pagamento com outra forma de pagamento?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
          }).then((x) => x.isConfirmed);
          if (!canContinue) {
            return;
          }
        }
        if (valAberto < valDisponivel) {
          this.pagamento.valor = this.formatFloatToReal(this.valorAberto);
          let canContinue = await this.$swal({
            title: "Divergência no valor do crédito!",
            text: "O valor do crédito selecionado é maior do que tem á pagar. Deseja utilizar o crédito selecionado parcialmente? \rO restate continuará disponível a ser utilizado pelo cliente!",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
          }).then((x) => x.isConfirmed);
          if (!canContinue) {
            return;
          }
        }
      }
      if (
        this.bandeiraSelected &&
        this.bandeiraSelected.debito == 0 &&
        this.pagamento.tipo == "Débito"
      ) {
        erros.push(
          `A bandeira ${this.bandeiraSelected.nome} não aceita Débito`
        );
      }
      if (
        this.bandeiraSelected &&
        this.bandeiraSelected.credito == 0 &&
        this.pagamento.tipo == "Crédito"
      ) {
        erros.push(
          `A bandeira ${this.bandeiraSelected.nome} não aceita Crédito`
        );
      }
      if (
        !this.edit &&
        Math.ceil(this.formatValorReal(this.pagamento.valor)) >
          Math.ceil(this.valorAberto)
      ) {
        erros.push("O valor informado é superior ao valor á ser recebido");
      }
      if (this.pagamento.forma == "Cartão") {
        if (!this.verificarAutDoc(false)) {
          erros.push("Autorização e Documento já utilizados anteriormente");
        }
        if (
          Validadores.string.IsNullOrEmpty(this.pagamento.autorizacao) ||
          this.pagamento.autorizacao.length != 6
        ) {
          erros.push(
            "Informe um número de autorização válido! Precisa conter 6 dígitos."
          );
        }
        if (
          Validadores.string.IsNullOrEmpty(this.pagamento.documento) ||
          this.pagamento.documento.length != 9
        ) {
          erros.push(
            "Informe um número de documento válido! Precisa conter 9 dígitos."
          );
        }
        if (Validadores.string.IsNullOrEmpty(this.pagamento.tipo)) {
          erros.push("Selecione o tipo de cartão DÉBITO/CRÉDITO");
        }
      }
      if (this.pagamento.forma == "Convenio" || this.pagamento.forma == "DEF") {
        if (!this.pagamento.entidadeId || this.pagamento.entidadeId <= 0) {
          erros.push(
            "Selecione " +
              (this.pagamento.forma == "Convenio"
                ? "a empresa!"
                : "o funcionário")
          );
        }
      }
      if (
        this.pagamento.forma == "DEF" &&
        (!this.pagamento ||
          !this.pagamento.entidade ||
          !this.pagamento.entidade.cpf ||
          !Validadores.string.validarCPF(this.pagamento.entidade.cpf))
      ) {
        erros.push("CPF INVÁLIDO! CORRIJA O CADASTRO ANTES DE CONTINUAR!");
      }

      if (erros.length > 0) {
        let errosDesc = await erros.reduce((ret, vl) => {
          ret += vl + "<hr/>";
          return ret;
        }, "");
        this.$swal({
          title: "Atenção!",
          icon: "error",
          html: `<b>Os campos abaixo precisam de atenção</b> <hr/>
                ${errosDesc}`,
        });
      } else {
        this.$emit("creating", null);

        this.$emit("salvar", this.pagamento);
      }
    },
  },
  beforeDestroy() {
    this.pagamento = null;
    this.$emit("creating", null);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/default.scss";

.disabled {
  opacity: 0.7;
  cursor: no-drop !important;
  .forma-item {
    cursor: no-drop !important;

    &:hover {
      border: 1px solid #ccc !important;
    }
  }
}
.formas {
  display: flex;

  .forma-item {
    width: 20%;
    min-width: 100px;
    height: 72px;

    border: 1px solid #ccc;
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      border-color: $primary;
    }
    position: relative;

    .icon {
      opacity: 0.2;
      position: absolute;
      right: 10px;
      bottom: 0px;
      font-size: 45px;
      padding: 0px;
      margin: 0px;
      width: 45px;
      height: 60px;
    }
  }
  .selected {
    background-color: $primary;
    border-color: $primary;
    color: white;
    .icon {
      opacity: 0.7;
    }
  }
}

.custom-file-label::after {
  content: "Selecionar" !important;
}

.cardCredito {
  margin-bottom: 20px;
  cursor: pointer;

  &:hover {
    background-color: $primary;
    color: white;
  }
}
.CardSelected {
  background-color: $primary;
  color: white;
  border-color: red;
  border-width: 3px;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
}
</style>
